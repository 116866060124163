<template>
  <v-dialog v-model="dialog" width="600">
    <v-card>
      <v-card-title class="pa-2">
        {{ isEdit ? "Muokkaa tehtävää" : "Lisää tehtävä" }}
        <v-spacer></v-spacer>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>

      <!-- task form -->
      <v-form ref="form">
        <div>
          <v-text-field
            v-model="title"
            class="px-2 py-1"
            solo
            flat
            label="Otsikko"
            autofocus
            hide-details
            :rules="required"
            @keyup.enter="isEdit ? save() : create()"
          ></v-text-field>

          <v-divider></v-divider>

          <v-textarea
            v-model="subtitle"
            class="px-2 py-1"
            solo
            flat
            label="Kuvaus..."
            hide-details
          ></v-textarea>

          <v-menu ref="menu" v-model="menu" :close-on-content-click="false" min-width="290">
            <template #activator="{ on, attrs }">
              <v-text-field
                v-model="dateFormatted"
                class="px-2 py-1"
                dense
                v-bind="attrs"
                v-on="on"
                solo
                flat
                label="Hoida viimeistään"
                append-icon="mdi-calendar"
                v-prevent-manual-input
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="dueDate"
              first-day-of-week="1"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>

          <v-autocomplete
            v-model="apartment"
            class="px-2 py-1"
            :items="activeApartments"
            item-value="_id"
            :item-text="fullAddress"
            label="Liittyy kohteeseen"
            no-data-text="Vuokrakohteen osoite"
            solo
            flat
            clearable
            chips
            :search-input.sync="searchInput"
            @change="searchInput = ''"
            @input.native="getApartments"
          ></v-autocomplete>

          <!-- <v-select
            v-model="delegatedTo"
            :items="accountUsers"
            label="Näytä tehtävä myös käyttäjälle"
            no-data-text="Näytä tehtävä myös käyttäjälle"
            item-value="_id"
            item-text="name"
            class="px-2 py-1"
            solo
            flat
            clearable
            multiple
            small-chips
            deletable-chips
          ></v-select> -->

          <v-select
            v-model="taskLabels"
            class="px-2 my-3"
            :menu-props="{ bottom: true, offsetY: true }"
            :items="labels"
            label="Kategoriat"
            item-value="id"
            hide-selected
            hide-details
            solo
            flat
            multiple
          >
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip
                v-if="item === Object(item)"
                v-bind="attrs"
                class="font-weight-bold"
                :color="item.color"
                outlined
                :input-value="selected"
                label
              >
                <span class="pr-2">
                  {{ item.title }}
                </span>
                <v-icon small @click="parent.selectItem(item)">close</v-icon>
              </v-chip>
            </template>

            <template v-slot:item="{ item }">
              <v-chip :color="item.color" label outlined small>
                {{ item.title }}
              </v-chip>
            </template>
          </v-select>
        </div>
      </v-form>

      <v-divider></v-divider>

      <v-card-actions class="pa-2">
        <v-btn outlined color="error" @click="close">Poistu</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="isEdit ? save() : create()">Tallenna</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from "vuex";
import mixins from "../../mixins/mixins";
import { debounce } from "../../utils/helpers";

/*
|---------------------------------------------------------------------
| TODO Compose Component
|---------------------------------------------------------------------
|
| Compose new tasks editor
|
*/
export default {
  mixins: [mixins],

  data() {
    return {
      searchInput: "",
      menu: false,
      dialog: false,
      dateFormatted: "",
      title: "",
      subtitle: "",
      apartment: null,
      dueDate: "",
      task: {},
      taskLabels: [],
      search: null,
      apartmentInput: "",
      delegatedTo: [],
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },

  computed: {
    ...mapState("todoApp", ["labels", "apartments"]),
    ...mapState("apartment", ["activeApartments"]),
    ...mapState("account", ["accountUsers"]),

    isEdit() {
      return this.task && this.task._id;
    },
  },

  watch: {
    dueDate() {
      this.dateFormatted = this.formatDate(this.dueDate);
    },

    apartmentInput: debounce(function (newVal) {
      this.searchApartment(`/api/v1/apartment/search-apartment?search=${newVal}`);
    }, 1000),
  },

  methods: {
    ...mapActions("todoApp", ["updateTask", "addTask", "getApartments"]),
    ...mapActions("apartment", ["searchApartment"]),

    open(task) {
      if (task) {
        this.task = task;
        this.title = this.task.title;
        this.subtitle = this.task.subtitle;
        this.dueDate = this.task.dueDate
          ? new Date(this.task.dueDate).toISOString().substring(0, 10)
          : "";
        this.apartment = this.task.apartment;
        this.taskLabels = this.task.labels;
      } else {
        this.task = {};
        this.title = "";
        this.subtitle = "";
        this.apartment = null;
        this.dueDate = "";
        this.taskLabels = [];
      }

      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    fullAddress(item) {
      return item.apartmentNumber ? item.address + ", " + item.apartmentNumber : item.address;
    },

    getApartments(event) {
      this.apartmentInput = event.target.value;
    },

    async create() {
      const { title, subtitle, dueDate, taskLabels, apartment } = this;

      const task = {
        title,
        subtitle,
        dueDate,
        labels: taskLabels,
        apartment,
      };

      if (this.$refs.form.validate()) {
        await this.addTask(task);
        this.close();
      } else {
        this.showPopup("Täytä ainakin otsikko!", "error");
      }
    },

    async save() {
      const { title, subtitle, dueDate, taskLabels, apartment } = this;

      const task = {
        title,
        subtitle,
        dueDate,
        labels: taskLabels,
        apartment,
        _id: this.task._id,
      };

      if (this.$refs.form.validate()) {
        await this.updateTask({
          ...task,
        });
        this.close();
      }
    },

    filter(item, queryText) {
      const hasValue = (val) => (val !== null ? val : "");

      const text = hasValue(item.title);
      const query = hasValue(queryText);

      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
    },
  },
};
</script>
