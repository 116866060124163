<template>
  <div class="py-3 min-w-0 w-full">
    <div class="display-1">Tehtävät</div>
    <div class="d-flex flex-grow-1 flex-row mt-2">
      <v-navigation-drawer
        v-model="drawer"
        :app="$vuetify.breakpoint.mdAndDown"
        :permanent="$vuetify.breakpoint.lgAndUp"
        floating
        class="elevation-1 rounded flex-shrink-0"
        :right="$vuetify.rtl"
        :class="[$vuetify.rtl ? 'ml-3' : 'mr-3']"
        width="250"
      >
        <todo-menu class="todo-app-menu pa-2" @open-compose="openCompose"></todo-menu>
      </v-navigation-drawer>

      <div class="d-flex flex-grow-1 flex-column">
        <v-toolbar class="hidden-lg-and-up flex-grow-0 mb-2">
          <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          <div class="title font-weight-bold">Valikko</div>
        </v-toolbar>
        <router-view
          :key="$route.fullPath"
          class="flex-grow-1"
          @edit-task="openCompose"
        ></router-view>
      </div>

      <todo-compose ref="compose" />
    </div>
  </div>
</template>

<script>
import TodoMenu from "../../components/ToDo/TodoMenu";
import TodoCompose from "../../components/ToDo/TodoCompose";
import mixins from "../../mixins/mixins";
import { mapState, mapActions } from "vuex";

/*
|---------------------------------------------------------------------
| TODO Application Component
|---------------------------------------------------------------------
|
| Application layout
|
*/
export default {
  title: "Tehtävät",

  mixins: [mixins],

  components: {
    TodoMenu,
    TodoCompose,
  },

  data() {
    return {
      drawer: null,
    };
  },

  computed: {
    ...mapState("todoApp", ["tasks"]),
  },

  watch: {
    tasks: {
      deep: true,
      handler(val) {
        this.getApartmentsById(
          val.map((el) => {
            if (el.apartment) return el.apartment._id;
          })
        );
      },
    },
  },

  async created() {
    try {
      await this.getTasks();
      // await this.getAccountUsers();
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    // ...mapActions("account", ["getAccountUsers"]),
    ...mapActions("todoApp", ["getTasks"]),
    ...mapActions("apartment", ["getApartmentsById"]),

    openCompose(task) {
      this.$refs.compose.open(task);
    },
  },
};
</script>
