var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.isEdit ? "Muokkaa tehtävää" : "Lisää tehtävä")+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-divider'),_c('v-form',{ref:"form"},[_c('div',[_c('v-text-field',{staticClass:"px-2 py-1",attrs:{"solo":"","flat":"","label":"Otsikko","autofocus":"","hide-details":"","rules":_vm.required},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.isEdit ? _vm.save() : _vm.create()}},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),_c('v-divider'),_c('v-textarea',{staticClass:"px-2 py-1",attrs:{"solo":"","flat":"","label":"Kuvaus...","hide-details":""},model:{value:(_vm.subtitle),callback:function ($$v) {_vm.subtitle=$$v},expression:"subtitle"}}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"min-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"prevent-manual-input",rawName:"v-prevent-manual-input"}],staticClass:"px-2 py-1",attrs:{"dense":"","solo":"","flat":"","label":"Hoida viimeistään","append-icon":"mdi-calendar"},model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"first-day-of-week":"1"},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.dueDate),callback:function ($$v) {_vm.dueDate=$$v},expression:"dueDate"}})],1),_c('v-autocomplete',{staticClass:"px-2 py-1",attrs:{"items":_vm.activeApartments,"item-value":"_id","item-text":_vm.fullAddress,"label":"Liittyy kohteeseen","no-data-text":"Vuokrakohteen osoite","solo":"","flat":"","clearable":"","chips":"","search-input":_vm.searchInput},on:{"update:searchInput":function($event){_vm.searchInput=$event},"update:search-input":function($event){_vm.searchInput=$event},"change":function($event){_vm.searchInput = ''}},nativeOn:{"input":function($event){return _vm.getApartments.apply(null, arguments)}},model:{value:(_vm.apartment),callback:function ($$v) {_vm.apartment=$$v},expression:"apartment"}}),_c('v-select',{staticClass:"px-2 my-3",attrs:{"menu-props":{ bottom: true, offsetY: true },"items":_vm.labels,"label":"Kategoriat","item-value":"id","hide-selected":"","hide-details":"","solo":"","flat":"","multiple":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [(item === Object(item))?_c('v-chip',_vm._b({staticClass:"font-weight-bold",attrs:{"color":item.color,"outlined":"","input-value":selected,"label":""}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}}},[_vm._v("close")])],1):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.color,"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.title)+" ")])]}}]),model:{value:(_vm.taskLabels),callback:function ($$v) {_vm.taskLabels=$$v},expression:"taskLabels"}})],1)]),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"outlined":"","color":"error"},on:{"click":_vm.close}},[_vm._v("Poistu")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"info"},on:{"click":function($event){_vm.isEdit ? _vm.save() : _vm.create()}}},[_vm._v("Tallenna")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }